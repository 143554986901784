@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

#Nav__Menu {
  background: transparent !important;
  opacity: 1 !important;
  box-shadow: none !important;
  backdrop-filter: saturate(0) !important;
  background-opacity: 0 !important;
  border: none !important;
}
.active {
  position: relative;
}
.active::after {
  position: absolute;
  content: '';
  width: 100% !important;
  height: 2px !important;
  left: 0;
  background-color: #21409a;
  bottom: -4px;
  transition: all 0.5s linear;
}

.swiper-scrollbar {
  @apply !bottom-0;
}
